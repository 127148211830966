<template>
    <div :style="info.state == 40 ? `padding:0` : ''" id="RefundDifference">
        <template v-if="info.state != 40">
            <p style="font-size: 16px;font-weight: bold">保证金差额退还申请书</p>
            <p style="margin-top: 10px;line-height: 20px;font-size: 14px">现申请退还保证金<span class="xiahuaxian">{{info.returnMoney}}</span>元，退款至本人蜜蛋平台美妆账户余额，退款后本人已缴保证金为 <span class="xiahuaxian">{{info.currentMoney}}</span>元。保证金收据自即日起作废失效。
            </p>
            <p :style="`text-align: center;margin-top: 50px;font-size: 14px;${countdownTime == -1 ? 'opacity: 0' : ''}`">
                <span style="color: #FF98A0">{{countdownTime}}</span>秒后可点击申请</p>
            <div class="button_container">
                <div @click="routerChange(1)" :style="`${countdownTime == -1 ? 'background-color:#FF98A0' : ''}`"
                     class="button_">持有收据申请
                </div>
                <div @click="routerChange(2)" :style="`${countdownTime == -1 ? 'background-color:#FF98A0' : ''}`"
                     class="button_">收据缺失申请
                </div>
            </div>
        </template>
        <applySuccess v-if="info.state == 40" :info="info"/>
    </div>
</template>

<script>
    import applySuccess from "./applySuccess";
    export default {
        name: "RefundDifference",
        components: {applySuccess},
        data() {
            return {
                countdownTime: 3,
                brandId: '',
                info: ''
            }
        },
        created() {
            document.title = '退保证金差额'
            this.brandId = this.$route.params.brandId
            this.GetApplyChangeDetails()
            this.Init()
        },
        methods: {
            Init() {
                if (sessionStorage.getItem('notShowDownTime')) return this.countdownTime = -1
                let timer = null;
                timer = setInterval(() => {
                    if (this.countdownTime == 0) {
                        clearInterval(timer)
                        timer = null
                    }
                    this.countdownTime--
                }, 1000)

            },
            GetApplyChangeDetails() {
                this.post('/PersonCenter/TeamAgent/GetApplyChangeDetails', {brandId: this.brandId}, true, 2).then(data => {
                    if (data.code != 1) {
                        return this.$dialog.confirm({
                            title: '提示',
                            message: data.msg
                        })
                            .then(e => {
                                this.$router.back()
                            })
                            .catch(e => {
                                this.$router.back()
                            })
                    }
                    this.info = data.response
                    if (this.info.state != 10 && this.info.state != 40) {
                        this.$router.push(`/apply1/${this.brandId}`)
                    } else {
                        sessionStorage.setItem('notShowDownTime', '1')
                    }
                })
            },
            routerChange(e) {
                if (this.countdownTime != -1) return
                if (e == 1) {
                    this.$router.push(`/apply1/${this.brandId}`)
                } else {
                    this.$router.push(`/apply2/${this.brandId}`)
                }
            }
        }
    }
</script>

<style lang="scss">
    #RefundDifference {
        text-align: left;
        box-sizing: border-box;
        padding: 20px 15px;

        .xiahuaxian {
            display: inline-block;
            min-width: 30px;
            text-align: center;
            border-bottom: 1px solid #333;
        }

        .button_container {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            padding-left: 5px;
            padding-right: 5px;

            .button_ {
                width: 46%;
                height: 33px;
                border-radius: 500px;
                background-color: #CCCCCC;
                color: #fff;
                font-size: 14px;
                line-height: 33px;
                text-align: center;
            }
        }
    }
</style>