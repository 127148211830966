<template>
    <div :style="`width: ${clientWidth}px;height: ${clientHeight}px`" id="applySuccess">
        <div class="title">保证金差额退款成功</div>
        <div class="content_box">
            <div class="content_item">
                <div class="item_box">
                    <div class="lebel">店主姓名：</div>
                    <div>{{info.agentName}}</div>
                </div>
                <div class="item_box">
                    <div class="lebel">手机号：</div>
                    <div>{{info.agentPho}}</div>
                </div>
                <div class="item_box">
                    <div class="lebel">退保证金差额：</div>
                    <div>{{info.returnMoney}}元</div>
                </div>
                <div class="item_box">
                    <div class="lebel">温馨提示：您可前往平台账户</div>
                    <div @click="toMyAccount" class="check_btn">点击查看</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "applySuccess",
        props: ['info'],
        data() {
            return {
                clientHeight: '',
                clientWidth: '',
            }
        },
        created() {
            document.title = '持有收据申请'
            this.clientHeight = document.documentElement.clientHeight
            this.clientWidth = document.documentElement.clientWidth
        },
        methods: {
            toMyAccount() {
                window.location.href = this.userHost + "/rolorweb/html/mycenter/myAccount.html?showMZ=1"
            }
        }
    }
</script>

<style lang="scss">
    #applySuccess {
        text-align: left;
        background-color: #F8F8F8;
        .title{
            padding: 15px;
        }
        .content_box{
            background-color: #fff;
            box-sizing: border-box;
            padding-left: 15px;
        }
        .item_box{
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding-right: 15px;
            justify-content: space-between;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #F1F1F1;
        }
        .lebel{
            color: #999999;
        }
        .check_btn{
            padding: 3px 10px;
            font-size: 8px;
            border-radius: 500px;
            color: #FF98A0;
            border: 1px solid #FF98A0;
        }
    }
</style>